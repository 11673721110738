$primaryColor: #9e292b;
$primaryColorOverlay: rgba(158, 41, 43, 0.12);
$secondaryColor: #3a424b;
$accentColor: #e98404;
$grayDarkColor: #3a424b;

$logoGradient: linear-gradient(35deg, #9e292b 35%, #f6a800);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") no-repeat center;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg")
	no-repeat center;
$logoBackgroundMobile: url("/images/logo/logo-dbildungsMobile.svg") no-repeat
	center;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar {
	.sc-branding {
		padding: 0;
		.cloud-logo {
			width: 100%;
			margin: 0;
			padding: 30px;
		}
		.sc-title {
			display: none;
		}
	}

	@media (max-width: 991px) {
		.sc-branding {
			padding: 0;
			.cloud-logo {
				background: $logoBackgroundTablet !important;
				height: 32px;
			}
		}
	}
}

@media (max-width: 575px) {
	.cloud-logo {
		background: $logoBackgroundMobile !important;
	}
}

.primary-color {
	color: $primaryColor;
	display: none;
}
.secondary-color {
	color: $secondaryColor;
	display: none;
}
@import "./alerts.scss";
@import "./colors";
@import "./bootstrap/scss/bootstrap-flex";

.input-group-addon {
	width: auto;
}

//TODO in BC-3743: media code needs to be changed and refactored in terms of implementation BC-3743
.alert-button {
	.dropdown-menu {
		left: -20.7rem !important;

			.dropdown-menu::before,
			.dropdown-menu::after {
				content: none !important;
			}
		}
}

.navbar-nav {

	@include media-breakpoint-down(md) {
		height: 56px;
  }

	@include media-breakpoint-down(sm) {
		height: 56px;
  }
	.minimal-button {
		float: right;
		margin-top: 8px;
		padding-right: 10px;
		.dropdown-menu {
			&:after,
			&:before {
				display: none;
			}

			@include media-breakpoint-down(sm) {
				left: 19.75rem;
			}
		}
	}
}
